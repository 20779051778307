import EntityCache from './EntityCache';
import { action, computed, makeObservable, observable } from 'mobx';
import { appModel } from './App';
import { ChangedData, DDataNode } from '@simosol/iptim-data-model';
import { Stand, SyncStatus } from './Stands';
import { DescriptorComplex } from './DataNodeUtils';
import { LayersLoader } from './map/LayersLoader';
import Project from './Project';
import useEditorStands from './editor/useEditorStands';
import { EditorStand } from '@simosol/stands-map-editor';

export interface DPartialProject {
  uid: string;
  name: string;
  code: string;
  clients: string[];
  geom: {
    wkt: string,
  };
  data: DDataNode[];
  structure?: DescriptorComplex;
}

export interface DProject extends DPartialProject {
  structure: DescriptorComplex;
}
export type ProjectsChangedData = {[key: string]: ChangedData[]};

export default class Projects extends EntityCache<Project, DProject> {
  constructor() {
    super(
      data => new Project(data),
      data => data.uid,
      (project: Project, data: DProject) => project.setData(data),
    );
    makeObservable(this);
  }

  readonly layersLoader = new LayersLoader(() => {
    let selectedStands: Stand[] = [];
    this.selectedProjects.forEach((project) => {
      selectedStands = selectedStands.concat(project.stands);
    });
    return selectedStands;
  });

  @observable
  estateName: string = '';
  @observable
  estateCode: string = '';
  @observable
  clients: string = '';

  @computed
  get estateNameArray() {
    const estateNameArray = this.map(c => c.name ?? '');
    return estateNameArray.filter((item, pos) => estateNameArray.indexOf(item) === pos);
  }
  @computed
  get estateCodeArray() {
    const estateCodeArray = this.map(c => c.code ?? '');
    return estateCodeArray.filter((item, pos) => estateCodeArray.indexOf(item) === pos);
  }
  @computed
  get estateClientsArray() {
    const estateClientArray = this.map(c => c.clients ?? '').flat();
    return estateClientArray.filter((item, pos) => estateClientArray.indexOf(item) === pos);
  }

  @observable
  private _selectedProjects: Project[] = [];
  @computed
  get selectedProjects() { return this._selectedProjects; }

  get selectedIds() {
    return this.selectedProjects.map(v => v.id);
  }

  @action
  toggleProject = (project: Project) => {
    const index = this.selectedIds.indexOf(project.id);
    if (index > -1) {
      this._selectedProjects.splice(index, 1);
    } else {
      this._selectedProjects.push(project);
    }
  }

  onSelectAll = () => {
    if (this.isAllEstatesSelected) {
      this._selectedProjects = this._selectedProjects.filter(p => !this.filteredProjects.includes(p));
    } else {
      this._selectedProjects = this.filteredProjects.map(v => v);
    }
  }

  clearSelectAll = () => {
    this._selectedProjects = [];
  }

  @computed
  get isAllEstatesSelected() {
    return this.filteredProjects.every(p => this.selectedProjects.includes(p));
  }

  @computed
  get version() {
    let sum = 0;
    this.forEach((project) => {
      sum += project.version;
    });
    return sum;
  }

  @computed
  get changedData(): ProjectsChangedData {
    const res: ProjectsChangedData = {};
    this.forEach((project) => {
      const projectChangedData = project.changedData;
      if (projectChangedData.length === 0) return;
      res[project.id] = project.changedData;
    });
    return res;
  }

  @action
  getStand = (standId: string): Stand | undefined => {
    let stand: Stand | undefined;
    this.forEach(((project) => {
      if (!stand) stand = project.getStand(standId);
    }));
    return stand;
  }

  showProject = (project: Project) => {
    appModel.browser.page = { p: 'project', p1: project.id };
  }
  openEditor = (project: Project) => {
    appModel.browser.page = { p: 'editor', p1: project.id };
  }
  openMap = (project: Project) => {
    appModel.browser.page = { p: 'map', p1: 'project', p2: project.id };
  }

  commit = () => {
    this.forEach(project => project.commit());
  }

  createStands = async () => {
    const projects = this.map(project => project);
    for (const project of projects) {
      await project.createStands();
    }
  }

  @computed
  get standsCreated(): number {
    return this.reduce((total, project) => total + project.standsCreated, 0);
  }

  @computed
  get standsTotal(): number | undefined {
    if (this.length === 0) return undefined;
    let total: number | undefined = 0;
    this.forEach((project) => {
      if (total === undefined) return;
      const projectTotal = project.standsTotal;
      total = projectTotal !== undefined ? total + projectTotal : undefined;
    });
    return total;
  }
  @computed
  get standsAll(): Stand[] {
    if (this.length === 0) return [];
    let total: Stand[] = [];
    this.forEach((project) => {
      total = [...total, ...project.stands];
    });
    return total;
  }
  @computed
  get unsavedStands(): Stand[] {
    return this.standsAll.filter(s => s.syncStatus === SyncStatus.add || s.syncStatus === SyncStatus.del);
  }
  @computed.struct
  get editorStandsAll(): EditorStand[] {
    return useEditorStands(this.standsAll.filter(s => s.syncStatus !== SyncStatus.del));
  }

  @computed.struct
  get filteredProjects() {
    const estateName = new RegExp(this.estateName, 'i');
    const estateCode = new RegExp(this.estateCode, 'i');
    const clientName = new RegExp(this.clients, 'i');
    return this
      .filter(estate => estateName.test(estate.name))
      .filter(estate => this.clients ? estate.clients.find(c => clientName.test(c)) : true)
      .filter(estate => estateCode.test(estate.code));
  }
}
